@import './base/_config'

code
pre
pre code
  background grey-darker
  color grey-light

html
  background-color body-background-dark

body
  color grey-light

.content
  h1
  h2
  h3
  h4
  h5
  h6
  a
    color grey-lighter

  blockquote
    color grey-light
    border-left-color grey-lighter

  a
    border-bottom-color grey
    &:hover
      border-bottom-color white

.home
  .full-page
    &.bg-gray-200
      background-color: #313536;
    &.bg-gray-400
      background-color: #222325;
